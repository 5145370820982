.tools-and-title-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.btn {
	border: none;
	background-color: var(--primary-color);
	width: 10rem;
	height: 3.5rem;
	color: var(--font-color-text);
	font-size: 1rem;
	font-weight: bold;
	border-radius: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	cursor: pointer;
	background-color: var(--btn-color);
}

.btn:hover {
	transition: all 100ms linear;
	transform: scale(1.1);
}

.add-folder-btn {
	width: 12rem;
}

.btns-container {
	display: flex;
	gap: 1.5rem;
}

.btn-icon {
	font-size: 1.5rem;
}

.title {
	color: var(--font-color-heading);
	letter-spacing: 3px;
	text-transform: uppercase;
}

.underline {
	width: 10rem;
	height: 2px;
	background: var(--font-color-heading);
	margin-top: 0.5rem;
}

.files-container {
	margin-top: 3rem;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	row-gap: 3rem;
	column-gap: 2.5rem;
	margin-bottom: 2rem;
}

.adding-folder {
	width: 25rem;
}

.file-msg {
	margin-top: 5rem;
	text-align: center;
	font-size: 1.75rem;
	letter-spacing: 3px;
	color: var(--font-color-heading);
}

.mobile-title {
	color: var(--font-color-heading);
	letter-spacing: 3px;
	text-transform: uppercase;
}

.mobile-underline {
	width: 6rem;
	height: 2px;
	background: var(--font-color-heading);
	margin-top: 0.5rem;
}

.mobile-title-container {
	margin-top: 2rem;
	font-size: 0.8rem;
	display: none;
}

@media screen and (max-width: 720px) {
	.title {
		font-size: 1.5rem;
	}
	.underline {
		width: 6rem;
	}
	.adding-folder {
		width: 15rem;
	}
}

@media screen and (max-width: 680px) {
	.title-container {
		display: none;
	}
	.mobile-title-container {
		display: block;
	}
}

@media screen and (max-width: 400px) {
	.btn {
		width: 8rem;
	}
	.btn-text {
		font-size: 0.8rem;
	}
	.tools-and-title-container {
		justify-content: space-around;
	}
}
