.demons-backdrop {
	position: fixed;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;

	line-height: 1.25rem;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: rgba(204, 214, 246, 0.8);
	z-index: 999;
}

.demons-modal {
	background-color: #0a192f;
	width: 25rem;
	max-width: 95vw;
	border-radius: 10px;
	padding: 4rem 3rem;

	position: relative;
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.demons-intro {
	color: #ccd6f6;
	text-align: center;
	letter-spacing: 3px;
	font-size: 2rem;
	margin-top: 1rem;
}

.demons-name {
	display: inline-block;
	animation: bounce 1s ease infinite;
}

.demons-subintro {
	margin-top: 2rem;
	color: #40a295;
	text-align: center;
	letter-spacing: 3px;
	font-size: 1.25rem;
}

.demons-details {
	font-style: italic;
	color: #8892b0;
	text-align: center;
	letter-spacing: 1.5px;
	font-size: 1rem;
	text-align: start;
	margin-top: 1rem;
}

.demons-details-2 {
	font-style: italic;
	color: #8892b0;
	text-align: center;
	letter-spacing: 1.5px;
	font-size: 1rem;
	text-align: start;
	margin-top: 2rem;
}

.demons-github-button {
	background-color: transparent;
	padding: 0.75rem 1rem;
	border: #40a295 solid 2px;
	border-radius: 4px;
	text-decoration: none;
	color: #40a295;
	letter-spacing: 3px;
	text-align: center;
	margin-top: 1rem;
	transition: all linear 0.2s;
	width: fit-content;
	align-self: center;
}

.demons-github-button:hover {
	transform: scale(1.02);
}

.demons-close {
	position: absolute;
	color: #40a295;
	right: 0;
	top: 0;
	font-size: 1.5rem;
	padding: 1rem;
	cursor: pointer;
	transition: all linear 0.2s;
}

.demons-close:hover {
	transform: scale(1.5);
}

@keyframes bounce {
	70% {
		transform: translateY(0%);
	}
	80% {
		transform: translateY(-15%);
	}
	90% {
		transform: translateY(0%);
	}
	95% {
		transform: translateY(-7%);
	}
	97% {
		transform: translateY(0%);
	}
	99% {
		transform: translateY(-3%);
	}
	100% {
		transform: translateY(0);
	}
}

@media screen and (max-width: 480px) {
	.demons-intro {
		font-size: 1.5rem;
	}
}
