.form {
	display: flex;
	width: min-content;
}

.searchInput {
	border: none;
	outline: none;
	background: var(--searchbar-background);
	border-radius: 5px;
	padding-left: 2rem;
	padding-right: 1rem;
	width: 20rem;
	margin-left: -1rem;
	letter-spacing: 1.5px;
	font-size: 1.2rem;
	color: var(--font-color-heading);
}

.searchBtn {
	font-size: 2rem;
	border-radius: 25%;
	height: 2.5rem;
	width: 2.5rem;
	border: none;
	background-color: var(--searchbar-backgroundBtn);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	color: var(--primary-color);
	cursor: pointer;
	transition: all 200ms linear;
}

.searchBtn:hover {
	font-size: 2.3rem;
}

@media screen and (max-width: 400px) {
	.searchInput {
		width: 15rem;
	}
}
