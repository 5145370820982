.backscreen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(1, 1, 1, 0.5);
	z-index: 5;
	display: grid;
	place-content: center;
}

.modal {
	position: relative;
	background: var(--modal-background);
	height: 30rem;
	width: 25rem;
	max-width: 100vw;
	border-radius: 5px;
	z-index: 6;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: fit-content;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.file-upload-form {
	margin-top: 2rem;
	border: 2px dashed var(--border-color);
	width: 80%;
	height: 8.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 3rem;
}

.file-input-label {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	width: 100%;
	height: 100%;
	color: var(--uploader-fontcolor);
	letter-spacing: 2px;
	cursor: pointer;
	transition: all linear 100ms;
}

.file-input-label:hover {
	letter-spacing: 3px;
	gap: 1rem;
}

.file-input {
	display: none;
}

.file-input-submit-btn {
	margin-top: 2rem;
	background-color: var(--primary-color);
	height: 3rem;
	width: 10rem;
	border: none;
	color: var(--font-color-text);
	font-size: 1rem;
	text-transform: uppercase;
	letter-spacing: 3px;
	border-radius: 3px;
	cursor: pointer;
}

.file-input-submit-btn:hover {
	transition: all 100ms linear;
	transform: scale(1.05);
}

.file-input-submit-btn:disabled {
	transform: none;
	border: 1px solid #999999;
	background-color: #cccccc;
	color: #666666;
	cursor: not-allowed;
}

.file-input-label-icon {
	font-size: 2.5rem;
}

.progress-bars-container {
	overflow-y: scroll;
	margin-top: 1rem;
	width: 80%;
	height: fit-content;
	max-height: 25rem;

	/* Hiding Scrollbar */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.progress-bars-container::-webkit-scrollbar {
	display: none;
}

.file-upload-form-cross {
	position: absolute;
	top: 10px;
	right: 10px;
	color: rgb(255, 79, 79);
	cursor: pointer;
}

.file-upload-form-cross:hover {
	transform: scale(1.1);
}
