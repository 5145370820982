.file-container {
	height: 3.5rem;
	width: 100%;
	display: flex;
	gap: 1rem;
	padding: 0.75rem;
	background-color: var(--page-background);
	border-radius: 5px;
	margin-top: 0.75rem;
}

.upload-file-icon {
	font-size: 2rem;
	color: var(--file-icon-color);
}

.upload-details-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
}

.details {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.progress-bar {
	width: 100%;
	height: 0.5rem;
	background-color: var(--searchbar-background);
	border-radius: 20px;
}

.progress-bar-filler {
	background-color: var(--uploader-fontcolor);
	width: 0%;
	height: 100%;
	border-radius: inherit;
}

.upload-filename,
.upload-percentage {
	color: var(--uploader-fontcolor);
	letter-spacing: 1.5px;
}

.upload-checkmark {
	color: green;
}

@media screen and (max-width: 340px) {
	.upload-filename {
		font-size: 0.75rem;
	}
}
