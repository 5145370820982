@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.sidebar {
	background-color: var(--primary-color);
	height: 100vh;
	position: fixed;
}

.sidebar-links {
	color: var(--font-color-text);
	margin-top: 5rem;
	font-size: 1.1rem;
}

.link {
	margin-top: 2.5rem;
	display: flex;
	gap: 1rem;
	height: 1.7rem;
	align-items: center;
	transition: all 150ms linear;
}

.navlink {
	color: var(--font-color-text);
	text-decoration: none;
	width: 15rem;
	display: flex;
	gap: 1rem;
	padding-left: 2.8rem;
	border-left: 0.2rem solid transparent;
}

.active-nav-link {
	border-left: 0.2rem solid var(--font-color-text);
}

.logoContainer {
	display: flex;
	justify-content: center;
	gap: 3px;
	color: var(--font-color-logo);
	padding: 1rem;
}

.logo-up,
.logo-down,
.logo-icon {
	font-family: "Bebas Neue", cursive;
	font-size: 2.5rem;
}

.link-icon {
	color: var(--sidebar-icon-color);
}

@media screen and (max-width: 980px) {
	.sidebar {
		display: none;
	}
}
