@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");

.file {
	background: var(--file-background);
	height: 11rem;
	min-width: 16rem;
	border-radius: 10px;
	position: relative;
	color: var(--font-color-text);
	font-family: "Open Sans", sans-serif;
	cursor: pointer;
	transition: all 100ms linear;
}

.file:hover {
	transform: scale(1.2);
}

.filecontainer {
	display: flex;
	align-items: center;
	margin-top: 15px;
	max-width: 90%;
	max-height: 50%;
	margin-left: auto;
	margin-right: auto;
}

.fileicon {
	min-height: 4rem;
	min-width: 4rem;
	background: var(--fileicon-background);
	border-radius: 4px;
	display: grid;
	place-content: center;
}

.filename {
	letter-spacing: 1.5px;
	margin-left: 15px;
	font-size: 1rem;
	font-weight: bold;
	width: 70%;
	overflow-wrap: break-word;
	padding: 0px 10px;
}

.filestats {
	width: 50%;
	position: absolute;
	top: 100%;
	left: 100%;
	transform: translateX(-100%) translateY(-100%);
	padding: 0.2rem 0.5rem 0.5rem 0.5rem;
	font-weight: bold;
	letter-spacing: 1.5px;
	display: flex;
	justify-content: flex-end;
}

.filestat {
	width: fit-content;
}

.icon {
	color: var(--file-icon-color);
	min-height: 3rem;
	min-width: 3rem;
}

.delete-btn {
	background: transparent;
	color: transparent;
}

.file:hover .delete-btn {
	position: absolute;
	top: 97%;
	transform: translateY(-100%);
	margin-left: 15px;
	font-size: 1.5rem;
	font-weight: bold;
	color: var(--file-icon-color);
	background-color: var(--fileicon-background);
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	display: grid;
	place-content: center;
}

.is-touch {
	position: absolute;
	top: 97%;
	transform: translateY(-100%);
	margin-left: 15px;
	font-size: 1.5rem;
	font-weight: bold;
	color: var(--file-icon-color);
	background-color: var(--fileicon-background);
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	display: grid;
	place-content: center;
}

@media only screen and (max-width: 720px) {
	.delete-btn {
		position: absolute;
		top: 97%;
		transform: translateY(-100%);
		margin-left: 15px;
		font-size: 1.5rem;
		font-weight: bold;
		color: var(--file-icon-color);
		background-color: var(--fileicon-background);
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		display: grid;
		place-content: center;
	}
}

@media screen and (max-width: 400px) {
	.file:hover {
		transform: scale(1.03);
	}
}
