.input-field {
	border: none;
	background-color: transparent;
	border-bottom: 2px solid var(--font-color-text);
	outline: none;
	color: var(--font-color-text);
	letter-spacing: 1.5px;
	font-size: 1.2rem;
	text-align: center;
}

.input-field::placeholder {
	color: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 720px) {
	.input-field {
		width: 9rem;
	}
}

@media screen and (max-width: 400px) {
	.input-field {
		font-size: 0.9rem;
	}
}
